import React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const TestimonialsPage = () => {
  const testimonials = [
    {
      name: 'Jennifer',
      by: 'mum of',
      students: 'Niamh and Orlagh',
      content: `My oldest daughter (11) has been working on her technique.. I see big improvements.

My younger daughter (7) was scared in the water, she kept saying she was scared.. 

Week 3 and she is swimming with her face under the water.

The swimming teachers are amazing, they made my girls feel confident, they really have been amazing with them.

Thank you very much`,
      image: (
        <StaticImage
          src="../images/testimonial/jennifer.webp"
          alt="Niamh and Orlagh"
          height={400}
          width={400}
          transformOptions={{
            cropFocus: 'left',
            fit: 'cover',
          }}
        />
      ),
    },
    {
      name: 'Clare',
      by: 'mum of',
      students: 'Christopher and Abbie',
      content: `Both my children attend 1-2-1 lessons. Christopher used to attend the local swimming class in our area (hes 6 )  I found he wasn't getting enough attention , but has came on leaps and bounds so much since. Excellent teaching tailored to his individual progress. Sometimes he likes to have a wee carry on but Lara knows how to get his attention back. And also Abbie who's just turned 4 has became an amazing wee swimmer too she's so confident about going under the water. She looks forward to her lesson.  Overall thank you so much Becca and Cathie, for making both my children very confident in the water and worth every penny 👌🏼 x`,
      image: (
        <StaticImage
          src="../images/testimonial/clare.webp"
          alt="Christopher and Abbie"
          height={400}
          width={400}
          transformOptions={{
            cropFocus: 'left top',
            fit: 'cover',
          }}
        />
      ),
    },
    {
      name: 'Kellyann',
      by: 'mum of',
      students: 'Megan',
      content: `Fabulous team of teachers that have amazing patience with the kids. My 8 year old daughter loves going for her lesson each week and they have boosted her confidence as well as teaching her life saving skills. Can't recommend this swimming school enough`,
      image: (
        <StaticImage
          src="../images/testimonial/kellyann.webp"
          alt="Megan"
          height={400}
          width={400}
          transformOptions={{
            cropFocus: 'center',
            fit: 'cover',
          }}
        />
      ),
    },
  ];

  return (
    <Layout styles="testimonials" title="Testimonials">
      <div className="testimonial-container">
        {testimonials.length > 0
          ? testimonials.map((test, index) => (
              <div className="testimonial" key={`testimonial-${index}`}>
                <div className="test-image">{test.image}</div>
                <div className="test-content-container">
                  <div className="test-content">
                    <FontAwesomeIcon icon={faQuoteLeft} /> {test.content} <FontAwesomeIcon icon={faQuoteRight} />
                  </div>
                  <div className="test-name">
                    {test.name}, {test.by} {test.students}
                  </div>
                </div>
              </div>
            ))
          : 'No testimonials'}
      </div>
    </Layout>
  );
};

export default TestimonialsPage;
